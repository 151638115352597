import { Link } from "@quintype/components";
import { LeftArrow } from "../Icons/Icon";
import { CenterPromotionClaim } from "../../utils/gtm";
import React from "react";

export default function Subscribe({ props }) {
  const promo_data = props.data.collection.TopSubData;
  return (
    // <>
    //   {!isSubscribed ? (
    //     <div className="mb-5 flex flex-col items-center justify-center gap-3 bg-gray-50 py-5 text-center text-2xl font-medium text-primary dark:bg-zinc-800">
    //       <div>
    //         <p>{promo_data?.text}</p>
    //       </div>

    //       <Link
    //         href={promo_data?.redirect_url}
    //         target="_blank"
    //         onClick={() => CenterPromotionClaim()}
    //       >
    //         <div className="flex items-center">
    //           <p> {promo_data?.button.button_text}</p> <LeftArrow />
    //         </div>
    //       </Link>
    //     </div>
    //   ) : (
    //     <></>
    //   )}
    // </>

    <>
      <div className="mb-5 flex flex-col items-center justify-center gap-3 bg-gray-50 py-5 text-center text-2xl font-medium text-primary dark:bg-zinc-800">
        <div>
          <p>{promo_data?.text}</p>
        </div>

        <Link href={promo_data?.redirect_url} target="_blank" onClick={() => CenterPromotionClaim()}>
          <div className="flex items-center">
            <p> {promo_data?.button.button_text}</p> <LeftArrow />
          </div>
        </Link>
      </div>
    </>
  );
}
